<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      overlay-color="overlay"
    >
      <v-card class="text-center">
        <v-toolbar class="px-4" color="transparent" flat height="48">
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" class="pt-7 mr-4" justify="center">
              <v-btn @click="dialog=false" icon small><v-icon size="20" color="fontBody">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pb-7">
          <v-img contain :height="190" :src="require(`@/assets/alert-dialog/ax.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold primary--text">SUNAT INFORMA</span>
          <v-col class="text-left pb-0">
            Cumplimos con informar que SUNAT tiene programado un mantenimiento el día de hoy, lo cual afecta la emisión y recepción de comprobantes de pago electrónicos y guías de remisión electrónicas.
            <!-- <span class="d-block mb-2">Estimados clientes,</span>
            Les informamos que, el día de hoy <span class="font-weight-semibold">24 de septiembre de 2024,</span> hemos experimentado dificultades en el envío y consulta de documentos al Servicio de Impuestos Internos (SII) -->
            <span class="mt-3 d-block">Una vez finalizado el mantenimiento, Axteroid se encargará de regularizar todos los documentos pendientes.</span>
            <span class="d-block mt-4">
              Para más información: <a class="text-decoration-none" href="https://www.sunat.gob.pe/mensajes/diciembre/2024/aviso-ti-091224.html" target="_blank">Consulta aquí</a>
            </span>
            <span class="mt-3 d-block">Atentamente, <span class="d-block">Equipo Axteroid.</span></span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    data () {
      return {
        dialog: false,
        env: process.env?.VUE_APP_MODE ?? 'ax'
      }
    },
    created () {
      this.dialog = true
    }
  }
</script>